import { useEffect } from "react";
import ReactGA from "react-ga";
const TRACKING_ID = "G-JBJHB442R3";
ReactGA.initialize(TRACKING_ID);

const useGoogleAnalytics = () => {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
};

const getGoogleAnalytics = () => ReactGA.pageview(window.location.pathname + window.location.search);


export { getGoogleAnalytics, useGoogleAnalytics };
