import { Link, useParams } from "react-router-dom";
import { INoticesAll } from "../INotices";

const { REACT_APP_STRAPI_IMG } = process.env;

export default function CardLatestNews({
  latestNews,
}: {
  latestNews: INoticesAll[];
}) {
  const {id_page} = useParams();
  return (
    <ul className="w-100 px-2 mx-0">
      {latestNews?.map((item) => {
        return (
          <li className="lista-sin-estilo mb-4" key={item?.id}>
            <Link to={`/noticias/${id_page}/${item?.id}`} className="row link">
              <>
                <figure
                  className="col-4 overflow-hidden px-0 mx-0 rounded-2"
                  style={{
                    height: "100px",
                  }}
                >
                  <img
                    src={`${REACT_APP_STRAPI_IMG}${item?.url_image}`}
                    alt={item?.title_news}
                    className="h-100 object-cover"
                  />
                </figure>
                <figcaption className="col-8 mt-0 fnt-contisans-small text-dark">
                  <span>{item?.title_news}</span>
                </figcaption>
              </>
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
