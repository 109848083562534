import { ChangeEvent, FormEvent } from "react";
import magnify from "../../../assets/img/lupa.svg";
import { useSearchParams } from "react-router-dom";
//import magnify from "../../../../assets/img/lupa.svg";

interface ISearchBarSection {
  id: string;
  className?: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (e: FormEvent<HTMLFormElement>) => void;
}

const SearchBarSection = ({ id, handleChange, handleSubmit, className = "pt-3" }: ISearchBarSection) => {
  const [searchParams] = useSearchParams();
  return (
    <section className={`${className} container px-0`}>
      <form className="w-100 d-flex gap-2 align-items-center border border-2 px-lg-3 px-2 rounded" onSubmit={handleSubmit}>
        <label htmlFor={id}>
          <figure>
            <img src={magnify} alt="Icono para busqueda" />
          </figure>
        </label>
        <input
          type="search"
          id={id}
          defaultValue={searchParams.get('keySearch') ?? ''}
          placeholder="Buscar"
          onChange={(e) => handleChange(e)}
          className="w-100 p-lg-3 p-2 border border-0 outline-none"
        />
      </form>
    </section>
  );
};

export { SearchBarSection };
