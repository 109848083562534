import React from "react";
export default function CalendarIcon({
  color = "#707070",
  className,
  width = "15.733",
  height = "17.333",
}: {
  color?: string;
  width?: string;
  height?: string;
  target?: boolean;
  className?: string;
}): React.ReactElement {
  return (
    <figure className={`cursor-pointer text-sm ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="17"
        height="20"
        viewBox="0 0 23.065 25.406"
      >
        <g
          id="Icon_feather-calendar"
          data-name="Icon feather-calendar"
          transform="translate(1 1)"
        >
          <path
            id="Trazado_54"
            data-name="Trazado 54"
            d="M4.341,2.667H20.725a2.341,2.341,0,0,1,2.341,2.341V21.391a2.341,2.341,0,0,1-2.341,2.341H4.341A2.341,2.341,0,0,1,2,21.391V5.007A2.341,2.341,0,0,1,4.341,2.667Z"
            transform="translate(-2 -0.326)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Trazado_55"
            data-name="Trazado 55"
            d="M10.667,1.333V6.015"
            transform="translate(4.547 -1.333)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Trazado_56"
            data-name="Trazado 56"
            d="M5.333,1.333V6.015"
            transform="translate(0.518 -1.333)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Trazado_57"
            data-name="Trazado 57"
            d="M2,6.667H23.065"
            transform="translate(-2 2.696)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </figure>
  );
}
