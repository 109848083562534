import { INavbarList } from "../components/navbars/INavbarList";
import getPageData from "./getPageData";

interface IPageParams {
  path_name: string;
  navbar_item_index: number;
}

let page_data: INavbarList[] = [];

const getPageID = async <T>({ path_name, navbar_item_index }: IPageParams) => {
  let session = JSON.parse(sessionStorage.getItem("v1/api/pages") as string);

  if (session === null) {
    session = await getPageData("v1/api/pages");
  }

  //Searching in the nested pages (child page)
  page_data = session[navbar_item_index].nested_pages.filter(
    (page: INavbarList) => page.page_slug === path_name
  );

  // Searching in the parent page if not found in the nested page.
  if (page_data.length === 0) {    
    page_data = session.filter(
      (page: INavbarList) => page.page_slug === path_name
    );
  }

  return page_data[0].id;
};

export { getPageID };
