import { StoreApp } from "../../../views/Home/IHomePageData";
import {
  IconButtonGooglePlay,
  IconButtonAppStore,
} from "../FooterAppStoreButton";

interface Props {
  data: StoreApp[];
}

const FooterInternetApps = ({ data }: Props) => {
  return (
    <>
      <div className="col-12 col-md-6 col-lg-3 col-xl-2 mt-4 mt-lg-0 px-0">
        <>
          {data.map(
            ({ id, title, url_google_play, url_app_store, published }) =>
              published === "show" && (
                <div className="mb-3" key={id}>
                  <h5 className="fnt-contisans-title-footer text-lg-center text-start text-verde-limon">
                    {title}
                  </h5>
                  <div className="my-3">
                    <div className="d-flex justify-content-lg-center justify-content-start mt-1">
                      <IconButtonGooglePlay url={url_google_play} />
                      <IconButtonAppStore url={url_app_store} />
                    </div>
                  </div>
                </div>
              )
          )}
        </>
      </div>
    </>
  );
};

export default FooterInternetApps;
