import { useState, useEffect } from "react";
import { INavbarList } from "../components/navbars/INavbarList";
import getPageData from "../utils/getPageData";

const { REACT_APP_API_VERSION } = process.env;

const useNavbar = () => {
  const [fetchedNavbarItems, setFetchedNavbarItems] = useState<INavbarList[]>(
    []
  );

  const [offsetY, setOffsetY] = useState(0);
  useEffect(() => {
    const url = `${REACT_APP_API_VERSION}/api/pages`;
    getPageData<INavbarList[]>(url).then((data) => {            
      setFetchedNavbarItems(data);
    });

    const onScroll = () => {
      setOffsetY(window.pageYOffset);
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return {
    fetchedNavbarItems,
    offsetY,
  };
};

export { useNavbar };
