interface Props {
  message?: string;
}
const ContactFormModalSuccess = ({
  message = " Agradecemos o seu contato. <br /> Sua mensagem foi enviada com sucesso. <br /> Em breve nossa equipe de atendimento entrará em contato. <br />",
}: Props) => {
  return (
    <div
      className="modal fade"
      id="exampleModal"
      tabIndex={-1}
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <p className="text-gris fnt-myriad-bold fs-myriad-md mb-0">
              E-mail enviado.
            </p>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <p
              className="text-gris fnt-contisans-small mb-0"
              dangerouslySetInnerHTML={{ __html: message as string }}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-radius text-dark fs-myriad-lg bg-amarillo-nbc"
              data-bs-dismiss="modal"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactFormModalSuccess;
