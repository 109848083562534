import { useEffect, useState } from "react";
import { StoreApp } from "../views/Home/IHomePageData";
import getPageData from "../utils/getPageData";

/* 
  TODO Hook para obtener todos los datos de las aplicaciones
*/

const useStoreApp = () => {  
  /* const [storeAppData, setStoreAppsData] = useState<StoreApp[]>([]); */
  const {REACT_APP_API_VERSION} = process.env;
  const [storeAppData, setStoreAppsData] = useState<StoreApp[]>([]);
  
  useEffect(() => {
    const homeStoreAppUrl = `${REACT_APP_API_VERSION}/api/store-app`;
    getPageData<StoreApp[]>(homeStoreAppUrl).then(data => {
      setStoreAppsData([...data])
    }).catch(err => console.log(err));
  }, []);

  return {
    storeAppData
  }
  
};

export { useStoreApp };
