import { useParams } from "react-router-dom";
import InfoPageHeaderSolidBackground from "../../components/headers/InfoPageHeaderSolidBackground";
import { useHeaderInformations } from "../../hooks/useHeaderInformations";
import CardNotices from "./components/CardNotices";
import { useNoticesPage } from "./hooks/useNoticesPage";
import ButtonMoreNotices from "./components/ButtonMoreNotices";
import { useLatestNews } from "./hooks/useLatestNews";
import CardLatestNews from "./components/CardLatestNews";
import { SearchBarSection } from "./components/SearchBarSection";
import CardArquivos from "./components/CardArquivos";
import { useNoticesMonth } from "./hooks/useNoticesMonth";

export default function NoticesPage() {
  const { id_page } = useParams();
  const { headerInfo } = useHeaderInformations(id_page!);
  const { noticesMonthCategory } = useNoticesMonth();

  const {
    fetchedNotices,
    handleSubmit,
    handleChange,
    currentPage,
    handleMoreNews,
  } = useNoticesPage();
  const { latestNews } = useLatestNews();

  return (
    <>
      <InfoPageHeaderSolidBackground
        title={headerInfo[0]?.header_info_title}
        description={headerInfo[0]?.header_info_description}
      />
      <main className="container my-5 px-4">
        <aside className="row">
          <ul className="col-lg-9 col-12">
            <CardNotices notices={fetchedNotices} />
            {fetchedNotices[0]?.totalCount > currentPage &&
              fetchedNotices.length !== 0 && (
                <div className="w-100 d-flex justify-content-center">
                  <ButtonMoreNotices handleChange={handleMoreNews} />
                </div>
              )}
          </ul>
          <section className="col-lg-3 col-12">
            <SearchBarSection
              id="searchBarNotices"
              handleSubmit={handleSubmit}
              handleChange={handleChange}
            />
            <div className="my-4">
              <h2 className="text-dark fs-myriad-title fs-myriad">
                Últimas notícias
              </h2>
              <span className="border-bottom-title"></span>
            </div>
            <CardLatestNews latestNews={latestNews} />
            <CardArquivos noticesMonthCategory={noticesMonthCategory}/>
          </section>
        </aside>
      </main>
    </>
  );
}
