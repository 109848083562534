import ModalCard from "./ModalCard";
import IHeaderInformation from "../../../types/HeaderInformationTypes/IHeaderInformationTypes";

interface IHeader extends IHeaderInformation {
  vClassNameHeader?: string;
  border?: boolean;
  topTitle?: boolean;
}

const SectionHeader = ({
  smallTitle,
  title,
  border = true,
  topTitle,
  vClassNameHeader = "header-section",
  modalCardData,
  linkTitle,
  link,
}: IHeader) => {
  return (
    <section className={vClassNameHeader}>
      <div className="container-fluid container-xl d-flex flex-column justify-content-center">
        <div className="row justify-content-center justify-content-xl-start">
          <div className="col-12 col-lg-9 d-lg-flex flex-column justify-content-center">
            {smallTitle && topTitle && (
              <p className="text-blanco fnt-myriad-thin fnt-normal text-center text-xl-start ps-1 mb-2 mb-lg-0">
                {smallTitle}
              </p>
            )}
            <h2 className="text-blanco fnt-myriad-thin fnt-large text-center text-xl-start">
              {title}
            </h2>
            {border && <span className="border-bottom-title"></span>}
            {smallTitle && !topTitle && (
              <p className="text-blanco fnt-myriad-thin fnt-normal text-center text-xl-start ps-1">
                {smallTitle}
              </p>
            )}
            {linkTitle && (
              <div className="row">
                <div className="col-12 col-xl-5 text-center text-xl-start">
                  <a
                    href={link}
                    className="btn btn-radius bg-amarillo-nbc mt-4 mt-xl-0"
                  >
                    {linkTitle}
                    <i className="fas fa-arrow-right ps-2"></i>
                  </a>
                </div>
              </div>
            )}
          </div>
          {modalCardData && (
            <div className="col-12 col-sm-10 col-md-9 col-lg-6 mt-4 modal-card-container">
              {modalCardData && <ModalCard {...modalCardData} />}
            </div>
          )}
        </div>
        {/* <Mensaje hideMsgIcon={showIconMessage} /> */}
      </div>
    </section>
  );
};
export default SectionHeader;
