import { useEffect, useState } from "react";
import getPageData from "../utils/getPageData";
import {ILoansFGTSHeader} from "../views/ForYou/LoansFGTS/ILoansFGTSPage";

const useHeaderInformations = (page_id: string) => {
  const [headerInfo, setHeaderInfo] = useState<ILoansFGTSHeader[]>([]);

  useEffect(() => {
    const creditCardHeaderURL = `v1/api/headerinformations?page_id=${page_id}`;
    getPageData<ILoansFGTSHeader[]>(creditCardHeaderURL).then((data) => setHeaderInfo(data));
  }, [page_id]);

  return {
    headerInfo
  }
};

export {
    useHeaderInformations
}
