import { IFooter } from "../../../types/Footer/IFooter";
import { FooterItems } from "./FooterItems";

const FooterAboutUs = ({
  footer_title,
  nested_footer
}: IFooter) => {        
  return (
    <div className="col-12 col-md-6 col-lg-2 d-flex flex-column align-items-start mb-lg-4 mb-0 px-0">
      <p className="fnt-contisans-title-footer text-verde-limon mb-2">
        {footer_title}
      </p>
      <FooterItems nested_footer={nested_footer} />
    </div>
  );
};

export default FooterAboutUs;
