import { useState, useEffect } from "react";
import IBusinesInformation from "../types/BusinesInformationTypes/IBusinesInformationTypes";
import getPageData from "../utils/getPageData";
import IHomePageData from "../views/Home/IHomePageData";
import { useStoreApp } from "./useStoreApp";

const useFooterData = () => {
  const {REACT_APP_API_VERSION} = process.env;

  const [fetchedData, setFetchedData] = useState<IBusinesInformation>({
    id: 0,
    busines_info_sac: "",
    busines_info_telephone: "",
    busines_info_mail: "",
    busines_info_whatsapp: "",
    busines_info_twitter: "",
    busines_info_facebook: "",
    busines_info_instagram: "",
    busines_info_linkedin: "",
    busines_info_ombudsman_phone: "",
    busines_info_complaint_chanel: "",
    busines_info_matrix_title: "",
    busines_info_matrix_address: "",
    busines_info_trade_dolar: "",
  });

  //Obteniendo los datos de las aplicaciones de NBC / Cartoes
  let {storeAppData} = useStoreApp();

  useEffect(() => {
    const urlBusiness = `${REACT_APP_API_VERSION}/api/businessinformations`;

    //Obteniendo los datos generales de NBC.
    getPageData<IHomePageData[]>(urlBusiness)
      .then((data) => {                        
        setFetchedData(data[0]);
      })
      .catch((err) => console.log(err));
  }, []);

  return {
    fetchedData,
    storeAppData
  };
};

export { useFooterData };
