export const handleScroll = () => {
  document.body.classList.remove("stop-scroll");
  const checkedHambur = document.getElementById('menu-navbar') as HTMLInputElement;
  checkedHambur.checked = false 
};

const blockScroll = () => {
  document.body.classList.toggle("stop-scroll");
};

const HamburgerMenu = () => {
  return (
    <>
      <input type="checkbox" id="menu-navbar" hidden />
      <div className="menu cross menu--1 d-xl-none" onClick={blockScroll}>
        <label htmlFor="menu-navbar" className="lbl-hambur">
          <svg viewBox="-30 0 100 100" xmlns="http://www.w3.org/2000/svg">
            <circle cx="50" cy="50" r="30" />
            <path className="line--1 hamburguer-stroke" d="M0 40h62c13 0 6 28-4 18L35 35" />
            <path className="line--2 hamburguer-stroke" d="M10 50h50" />
            <path className="line--3 hamburguer-stroke" d="M0 60h62c13 0 6-28-4-18L35 65" />
          </svg>
        </label>
      </div>
    </>
  );
};

export default HamburgerMenu;
