import { useEffect, useRef, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import ReCAPTCHA from "react-google-recaptcha";
import { useCaptcha } from "../../hooks/useCaptcha";
import LoadingCircle from "../../components/loading/LoadingCircle";
import { transformAndSendData } from "../../utils/transformAndSendData";
import ComplaintsSchema from "./ComplaintsSchema";
import ContactFormModalSuccess from "../Contact/ContactFormModalSuccess";
import { getPageID } from "../../utils/getPageId";
import getPageData from "../../utils/getPageData";
import { IIdentificationRequest } from "../../types/IdentificationRequest/IIdentificationRequest";
import NumberFormat from "react-number-format";

interface FormData {
  identifications: string;
  outro?: string;
  administrador: string;
  data: string;
  local: string;
  denunciado: string;
  cargo: string;
  denuncia: string;
  nome: string;
  emailDenuncia: string;
}

interface FormDataParams {
  identificationDescription: string;
  communicationNbc: string;
  data: string;
  local: string;
  defendantName: string;
  reportedEmployeePosition: string;
  complaintDescription: string;
  writersName?: string;
  writersEmail?: string;
}

const { REACT_APP_URL, REACT_APP_API_VERSION } = process.env;

//const CAPTCHA_KEY = "6LfhEwMhAAAAAPErF-BzXOdnneFqv_4keemFpBrq";
const route_data = {
  path_name: "/denuncias",
  navbar_item_index: 3,
};

const route_endpoint = `${REACT_APP_URL!}${REACT_APP_API_VERSION}/api/ComplaintChannelForm`;
const date = new Date().toISOString().slice(0, 10);

export function ComplaintsForm() {
  const mailModal = useRef<any>(null);
  const [IdentificationsTypes, setIdentifications] =
    useState<IIdentificationRequest>();

  const {
    CAPTCHA_KEY,
    captchaRef,
    setSendingEmail,
    setCaptchaError,
    onChangeCaptcha,
    captchaError,
    sendingEmail,
  } = useCaptcha();

  //const schema = ContactSchema();
  const schema = ComplaintsSchema();

  const {
    control,
    register,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "all",
    reValidateMode: "onChange",
  });

  const outro = watch("identifications");

  const submitForm = (data: FormData) => {
    const dateSend = new Date(data.data);

    const dateFormat = new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "full",
    }).format(dateSend);

    if (captchaRef.current.getValue()) {
      const params = {
        identificationDescription:
          data.outro === "Outro" ? data.outro.trim() : data.identifications,
        communicationNbc: data.administrador.trim(),
        data: dateFormat,
        local: data.local.trim(),
        defendantName: data.denunciado.trim(),
        reportedEmployeePosition: data.cargo.trim(),
        complaintDescription: data.denuncia.trim().replace(/< /g, ""),
        writersName: data.nome.trim(),
        writersEmail: data.emailDenuncia.trim(),
      };

      setSendingEmail(true);

      transformAndSendData<FormDataParams>(route_endpoint, params)
        .then((resp) => {
          if (resp.status === 201) {
            setCaptchaError("");
            mailModal.current.click();
          }
        })
        .finally(() => setSendingEmail(false));
      return;
    }
    setCaptchaError("* Complete o Captcha");
    setSendingEmail(false);
  };
  useEffect(() => {
    getPageID(route_data).then((page_id) => {
      const urlEndpoint = `${REACT_APP_API_VERSION}/api/IdentificationRequest?pageId=${page_id}`;
      getPageData<IIdentificationRequest>(urlEndpoint).then((res) => {
        setIdentifications(res);
      });
    });
  }, []);

  return (
    <>
      <form
        style={{
          background: "#f7f7f7",
        }}
        className="contact px-4 px-lg-2 section-separator border-section"
        onSubmit={handleSubmit(submitForm)}
      >
        <div className="container px-0">
          <div className="row mb-3">
            <p className="text-dark fs-myriad fs-5 text-start mb-2">
              As comunicações referem-se a quem?
            </p>
            {IdentificationsTypes?.identifications?.map((input, index) => (
              <div className="col-12" key={input.id}>
                <label className="text-gris fnt-contisans-small text-start mb-2">
                  <input
                    type="radio"
                    className="form-check-input"
                    defaultChecked={index === 0 && true}
                    value={input?.description}
                    //name="identifications"
                    id={input?.description}
                    {...register("identifications")}
                  />
                  <span className="ms-2">{input.description}</span>
                </label>
              </div>
            ))}
            <div className="col-12 mt-2">
              {outro === "Outro" && (
                <input
                  placeholder="Outro"
                  className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                    errors.outro
                      ? "border border-danger bg-form-incomplete"
                      : ""
                  }`}
                  type="text"
                  {...register("outro")}
                />
              )}
            </div>
          </div>
          <div className="row mb-3">
            <p className="text-dark fs-myriad fs-5 text-start mb-2">
              A comunicação envolve os administradores do NBC Bank?
            </p>
            <label className="text-gris fnt-contisans-small text-start mb-2">
              <input
                type="radio"
                className="form-check-input"
                value="Sim"
                defaultChecked={true}
                {...register("administrador")}
              />
              <span className="ms-2">Sim</span>
            </label>
            <label className="text-gris fnt-contisans-small text-start mb-2">
              <input
                type="radio"
                className="form-check-input"
                value="Não"
                {...register("administrador")}
              />
              <span className="ms-2">Não</span>
            </label>
          </div>
          <div className="row mb-lg-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              {/* <input
                type="text"
                placeholder="* Data"
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.data ? "border border-danger bg-form-incomplete" : ""
                }`}
                {...register("data")}
              /> */}
              {window.screen.width > 1024 ? (
                <input
                  min="1900-01-01"
                  max={date}
                  type="date"
                  placeholder="dd/mm/aaaa"
                  className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                    errors.data ? "border border-danger bg-form-incomplete" : ""
                  }`}
                  id="data"
                  {...register("data")}
                />
              ) : (
                <Controller
                  control={control}
                  name="data"
                  render={({ field: { onChange, name, value } }) => (
                    <NumberFormat
                      onValueChange={(values) => {
                        onChange(values.formattedValue);
                      }}
                      displayType="input"
                      type="text"
                      format="##/##/####"
                      placeholder="dd/mm/aaaa"
                      className={`form-control mb-3 mb-lg-0 w-100 ${
                        errors.data
                          ? "border border-danger bg-form-incomplete"
                          : ""
                      }`}
                      id="DOB"
                      name={name}
                    />
                  )}
                />
              )}
              {errors.data && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.data.message}
                </small>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <input
                type="text"
                placeholder="* Local"
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.local ? "border border-danger bg-form-incomplete" : ""
                }`}
                {...register("local")}
              />
              {errors.local && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.local.message}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-lg-6 col-md-6 col-sm-12">
              <input
                type="text"
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.denunciado
                    ? "border border-danger bg-form-incomplete"
                    : ""
                }`}
                placeholder="* Nome do denunciado"
                {...register("denunciado")}
              />
              {errors.denunciado && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.denunciado.message}
                </small>
              )}
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12">
              <input
                type="text"
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.cargo ? "border border-danger bg-form-incomplete" : ""
                }`}
                placeholder="* Cargo do colaborador denunciado"
                {...register("cargo")}
              />
              {errors.cargo && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.cargo.message}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12">
              <textarea
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.denuncia
                    ? "border border-danger bg-form-incomplete"
                    : ""
                }`}
                {...register("denuncia")}
                placeholder="Descreva sua denúncia com o maior detalhamento possível"
                rows={6}
              ></textarea>
              {errors.denuncia && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.denuncia.message}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12">
              <label htmlFor="nome" className="mb-3 fnt-myriad-bold text-gris">
                Caso deseje identificar-se, digite seu nome no campo abaixo
              </label>
              <input
                type="text"
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.nome ? "border border-danger bg-form-incomplete" : ""
                }`}
                placeholder="* Opcional"
                {...register("nome")}
              />
              {errors.nome && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.nome.message}
                </small>
              )}
            </div>
          </div>
          <div className="row mb-lg-3">
            <div className="col-12">
              <label
                htmlFor="emailDenuncia"
                className="mb-3 fnt-myriad-bold text-gris"
              >
                Caso deseje acompanhar o andamento de sua comunicação digite um
                e-mail válido no campo abaixo
              </label>
              <input
                type="text"
                className={`form-control input-custom fs-myriad-small mb-3 mb-lg-0 ${
                  errors.emailDenuncia
                    ? "border border-danger bg-form-incomplete"
                    : ""
                }`}
                placeholder="* Opcional"
                {...register("emailDenuncia")}
              />
              {errors.emailDenuncia && (
                <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                  {errors.emailDenuncia.message}
                </small>
              )}
            </div>
          </div>

          <div
            className="row my-3"
            style={{
              width: "10px",
            }}
          >
            <ReCAPTCHA
              sitekey={CAPTCHA_KEY}
              onChange={onChangeCaptcha}
              ref={captchaRef}
              size={window.screen.width > 300 ? "normal" : "compact"}
              style={{
                maxWidth: "10px",
              }}
            />
            {captchaError && (
              <small className="text-danger fs-myriad fs-myriad-small mb-3 mb-lg-0 mt-3 d-block">
                {captchaError}
              </small>
            )}
          </div>
          <div className="row mb-3">
            <div className="col-12">
              <button
                className="btn btn-radius text-dark fs-myriad-lg bg-amarillo-nbc"
                disabled={sendingEmail}
              >
                Enviar
              </button>
              <button
                ref={mailModal}
                type="button"
                className="btn btn-primary d-none"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              ></button>
            </div>
          </div>
          {sendingEmail && <LoadingCircle />}
        </div>
      </form>
      <ContactFormModalSuccess />
    </>
  );
}
