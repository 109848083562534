import { Link } from "react-router-dom";
import { IFooterList } from "../../../types/Footer/IFooter";
//import { FooterNestedItems } from "./FooterNestedItem";
import { v4 as uuidv4 } from "uuid"; 
const { REACT_APP_STRAPI_IMG } = process.env;

const FooterItems = ({ nested_footer }: IFooterList) => {
  return (
    <ul className="p-0 m-0">
      {nested_footer?.map((page) => {
        return (
          <li className="lista-sin-estilo" key={uuidv4()}>
            {page.footer_page_slug ? (
              <div className="mb-2">
                {page?.nested_pages === null && (
                  <Link
                    //to={page.footer_page_slug}
                    to={`${page.footer_page_slug}/${page.page_id}`}
                    className="no-decoration text-blanco fnt-contisans-footer link "
                  >
                    {page?.footer_title}
                  </Link>
                )}
              </div>
            ) : (
              page?.nested_pages === null && (
                <div className="mb-2">
                  <a
                    href={`${REACT_APP_STRAPI_IMG}${page.footer_pdf_slug}`}
                    target={page?.open_new_page ? "_blank" : "_parent"}
                    className="no-decoration text-blanco fnt-contisans-footer link "
                    rel="noreferrer"
                  >
                    {page?.footer_title}
                  </a>
                </div>
              )
            )}
            {/*   : (
              <FooterNestedItems
                title={page?.footer_title}
                nested_pages={page?.nested_pages}
              /> */}
          </li>
        );
      })}
    </ul>
  );
};

export { FooterItems };
