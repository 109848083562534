export const getCurrentDate = async () => {
  const response = await fetch(`${process.env.REACT_APP_API_URL_SYS}/nbc-cotacoes-moedas-app/cotacoes/hora-atual`)

  if (response.status !== 200) {
    return new Date()
  } 

  const data = await response.text() // result: '"2024-12-05T09:30:06.83706087"'
  const validDateJS = data.replace(/"/g, "")
  
  const currentDate = new Date(validDateJS)

  return currentDate
}