import { useEffect, useState } from "react";
import getPageData from "../../../utils/getPageData";
import { NewsNoticeMonths } from "../INotices";

const { REACT_APP_API_VERSION } = process.env;

const useNoticesMonth = () => {
  const [noticesMonthCategory, setNoticesMonthCategory] = useState<
    NewsNoticeMonths[]
  >([]);

  useEffect(() => {
    const noticesURL = `${REACT_APP_API_VERSION}/api/newMonthYear`;
    getPageData<NewsNoticeMonths[]>(noticesURL).then((data) => {
      setNoticesMonthCategory(data);
    });
  }, []);

  return {
    noticesMonthCategory,
  };
};

export { useNoticesMonth };
