import imgInstagram from "../../../assets/img/instagram_icon.svg";
import imgLinkedin from "../../../assets/img/linkedin_icon.svg";
import imgFacebook from "../../../assets/img/facebook_icon.svg";

interface IFooterSocialMedia {
  facebook: string;
  instagram: string;
  linkedin: string;
}

const FooterSocialMedia = ({
  facebook,
  instagram,
  linkedin,
}: IFooterSocialMedia) => {
  return (
    /* justify-content-around justify-content-lg-start col-md-6 col-lg-6 col-lg-3  d-flex justify-content-start flex-wrap */
    <div
      className="col-12 col-md-6 col-lg-3
      text-white mt-4 mt-lg-0 p-0"
    >
      <p className="d-block fnt-contisans-title-footer w-100 p-0 mb-3">
        Siga o NBC
      </p>
      <div className="col-8 d-flex justify-content-start">
        <a
          href={instagram}
          rel="noreferrer"
          target="_blank"
          className="d-block"
        >
          <img
            src={imgInstagram}
            alt="instagram"
            loading="lazy"
            style={{ height: 30 }}
            className="img-vertical"
          />
        </a>
        <a href={linkedin} rel="noreferrer" target="_blank" className="d-block">
          <img
            src={imgLinkedin}
            alt="linkedin"
            loading="lazy"
            style={{ height: 30 }}
            className="img-vertical mx-3"
          />
        </a>
        <a href={facebook} rel="noreferrer" target="_blank" className="d-block">
          <img
            src={imgFacebook}
            alt="facebook"
            loading="lazy"
            style={{ height: 30 }}
            className="img-vertical"
          />
        </a>
      </div>
    </div>
  );
};

export default FooterSocialMedia;
