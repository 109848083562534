import { useNavigate, useParams } from "react-router-dom";
import useNoticesByID from "./hooks/useNoticesByID";
import InfoPageHeader from "../../components/headers/InfoPageHeader";
import CardLatestNews from "./components/CardLatestNews";
import { useLatestNews } from "./hooks/useLatestNews";
import { useNoticesPage } from "./hooks/useNoticesPage";
import { SearchBarSection } from "./components/SearchBarSection";
import ShareIcon from "./components/icons/ShareIcon";
import { useNoticesMonth } from "./hooks/useNoticesMonth";
import CardArquivos from "./components/CardArquivos";
import CalendarIcon from "./components/icons/CalendarIcon";
import ClockIcon from "./components/icons/ClockIcon";
import UserIcon from "./components/icons/UserIcon";

const { REACT_APP_STRAPI_IMG } = process.env;

export default function NoticesPageSlug() {
  const { id_notice, id_page } = useParams();
  const navigate = useNavigate();
  const { newBySlug } = useNoticesByID({
    id_new: id_notice!,
  });
  const { latestNews } = useLatestNews();
  const { handleChange, inputSearch } = useNoticesPage();
  const shareData = {
    text: newBySlug[0]?.title_news,
    url: window.location.href,
  };
  const { noticesMonthCategory } = useNoticesMonth();

  const handleSubmit = () =>
    navigate(`/noticias/${id_page}?keySearch=${inputSearch}`);

  return (
    <>
      <InfoPageHeader
        image={`${REACT_APP_STRAPI_IMG}${newBySlug[0]?.url_header_image}`}
        title={newBySlug[0]?.title_news}
        vclassName="glassmorphifims-notice"
      />
      <main className="container section-separator px-3">
        <section className="row">
          <div className="col-lg-9 col-12 px-3">
            <figure className="w-100 h-30rem overflow-hidden rounded-4">
              <img
                src={`${REACT_APP_STRAPI_IMG}${newBySlug[0]?.url_image}`}
                alt={newBySlug[0]?.subtitle_new}
                className="w-100 h-100 object-fit-cover"
              />
            </figure>
            <div className="d-flex flex-wrap gap-2 my-3">
              <div className="fnt-contisans-small d-flex align-items-center text-gris mb-0 pb-0">
                <UserIcon color="#5b5b62" className="text-gris"/>
                <p className="text-gris fs-myriad mb-0 ms-2 fs-myriad-md">
                  {newBySlug[0]?.author_name}
                </p>
              </div>
              {/* <p className="text-gris fs-myriad fs-myriad-md">
                {newBySlug[0]?.author_name}
              </p> */}
              <div className="fnt-contisans-small d-flex align-items-center text-gris mb-0 pb-0">
                <CalendarIcon color="#5b5b62" className="text-gris" />
                <p className="text-gris fs-myriad mb-0 ms-2 fs-myriad-md">
                  {newBySlug[0]?.publication_date}
                </p>
              </div>
              <div className="fnt-contisans-small d-flex align-items-center text-gris mb-0 pb-0">
                <ClockIcon color="#5b5b62" className="text-gris" />
                <p className="text-gris fs-myriad mb-0 ms-2 fs-myriad-md">
                  {newBySlug[0]?.publication_hour}
                </p>
              </div>

              <ShareIcon sharedData={shareData} />
            </div>
            <article
              className="text-gris fs-myriad-title fs-myriad  mb-lg-5 mb-3 text-start"
              dangerouslySetInnerHTML={{ __html: newBySlug[0]?.subtitle_new }}
            />
            <article
              className="text-gris fs-myriad fs-myriad-md text-start"
              dangerouslySetInnerHTML={{
                __html: newBySlug[0]?.description_new,
              }}
            />
          </div>
          <div className="col-lg-3 col-12 mt-3 mt-lg-0">
            <SearchBarSection
              id="searchBarNotices"
              className="pt-0"
              handleChange={handleChange}
              handleSubmit={handleSubmit}
            />
            <div className="my-4">
              <h2 className="text-dark fs-myriad-title fs-myriad">
                Últimas notícias
              </h2>
              <span className="border-bottom-title"></span>
            </div>
            <CardLatestNews latestNews={latestNews} />
            <CardArquivos noticesMonthCategory={noticesMonthCategory} />
          </div>
        </section>
      </main>
    </>
  );
}
