import IModalCard from '../../../types/ModalCardTypes/IModalCardTypes';

const ModalCard = ({ 
	description = '',
	bgColor = '#283566c2',
	smallInfo = 'Porque fazemos isso?',
	title = 'Abra sua conta no NBC Bank', 
	linkTitle = 'Inicie digitando seu CPF ou CNPJ',
}:IModalCard) => {
	return (
		<div className='card-cpf-cnpj rounded-start d-none' style={{backgroundColor: bgColor}}>
			<div className='card-body'>
				<div className='row p-3'>
					<div className='col-12 ps-4'>
						<h4 className='fnt-palanquin text-blanco mb-4 text-center text-xl-start'>{title}</h4>
						<p className='fnt-palanquin text-blanco mb-4 text-justify text-xl-start'>{description}</p>
						<div className='col-xl-10'>
							<button className='btn btn-iniciar-cpf-cnpj fnt-700' >
									<span className='text-gris-claro' >{linkTitle}</span>
									<i className='fas fa-arrow-right'></i>
							</button>
							{
								smallInfo && 
								<h6 className='text-start text-sm-end fnt-300 fnt-palanquin text-blanco-opacity mt-3'>{smallInfo}</h6>
							}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ModalCard;