import { useEffect, useState } from "react";
import getPageData from "../../../utils/getPageData";
import { INotices, INoticesAll } from "../INotices";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const { REACT_APP_API_VERSION } = process.env;

const useLatestNews = () => {
  const [latestNews, setLatestNews] = useState<INoticesAll[]>([]);

  useEffect(() => {
    const noticesURL = `${REACT_APP_API_VERSION}/api/news?page=1&pageSize=5`;
    getPageData<INotices[]>(noticesURL).then((data) => {
      const latestNews = data?.map((item: INoticesAll) => {
        const newTodayDate = format(
          new Date(item?.publication_date),
          "dd LLLL yyyy",
          {
            locale: ptBR,
          }
        );
        const newHourPublished = format(
          new Date(item?.publication_date),
          "HH:mm",
          {
            locale: ptBR,
          }
        );
        return {
          id: item?.id,
          title_news: item?.title_news,
          description_new: item?.description_new,
          subtitle_new: item?.subtitle_new,
          publication_date: newTodayDate,
          publication_hour: newHourPublished,
          author_name: item?.author_name,
          url_header_image: item?.url_header_image,
          url_image: item?.url_image,
        };
      });
      setLatestNews(latestNews);
    });
  }, []);

  return {
    latestNews
  }
};

export { useLatestNews };
