/* import ImageAndroid from "../../assets/img/AndroidLightLogo.svg";
import ImageApple from "../../assets/img/AppleLightLogo.svg"; */
import ImageGooglePlay from "../../assets/img/google-play.png";

interface BtnProps {
  blackButton?: boolean;
  url?: string;
}

/* Botones completos para las aplicaciones */
const BtnGooglePlay = ({
  blackButton = false,
  url = "https://play.google.com/store/apps/details?id=com.nbcbanking.grip&hl=pt_PT&gl=US",
}: BtnProps) => {  
  return (
    <a className="btn btn-app" target="_blank" href={url} rel="noreferrer">
      <div className="d-flex justify-content-center align-items-center">
        {/* width="30px" */}
        <img src={ImageGooglePlay} alt="Google play" />
      </div>
      <div className="ms-2 text-start d-flex flex-column">
        <small className="fw-light fnt-contisans-light">DISPONÍVEL NO</small>
        <label className="fnt-600">Google Play</label>
      </div>
    </a>
  );
};

const BtnAppStore = ({
  blackButton = false,
  url = "https://apps.apple.com/br/app/nbc-bank/id1602711464",
}: BtnProps) => {
  return (
    <a className="btn btn-app" target="_blank" href={url} rel="noreferrer">
      <div className="d-flex justify-content-center align-items-center">
        <i className="fab fa-apple"></i>
      </div>
      <div className="ms-2 text-start d-flex flex-column">
        <small className="fw-light fnt-contisans-light">BAIXAR NA</small>
        <label className="fnt-600">App Store</label>
      </div>
    </a>
  );
};

/* Botones para descargar la aplicacion solo con los iconos */

const IconButtonGooglePlay = ({ url }: BtnProps) => {
  return (
    <a target="_blank" href={url} rel="noreferrer">
      <i className="fab fa-google-play fnt-icon-apps me-3"></i>
    </a>
  );
};

const IconButtonAppStore = ({ url }: BtnProps) => {
  return (
    <a target="_blank" href={url} rel="noreferrer">
      <i className="fab fa-apple fnt-icon-apps"></i>
    </a>
  );
};

export default BtnGooglePlay;

export { BtnAppStore, IconButtonGooglePlay, IconButtonAppStore };
