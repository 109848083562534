interface Props {
  title: string;
  htmlDescription?: string;
  vClassName?: string;
}

const BreakedHeaderSections = ({
  title,
  htmlDescription,
  vClassName = "my-5",
}: Props) => {
  return (
    <section className="container px-0">
      <article className={`row mx-0 px-4 px-lg-2 ${vClassName}`}>
        <div className="mx-auto px-0">
          {
            title &&
            <h2 className="text-dark fs-myriad-xl text-center fs-myriad mb-3" dangerouslySetInnerHTML={{__html: title}}>
            </h2>
          }
          {htmlDescription && (
            <div className="px-0" dangerouslySetInnerHTML={{ __html: htmlDescription }}></div>
          )}
        </div>
      </article>
    </section>
  );
};

export { BreakedHeaderSections };
