import React from "react";
export default function UserIcon({
  color = "#212429",
  className,
}: {
  color: string;
  className?: string;
}): React.ReactElement {
  return (
    <figure className={`cursor-pointer text-sm ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22.775"
        height="25.372"
        viewBox="0 0 22.775 25.372"
      >
        <g
          id="Icon_feather-user"
          data-name="Icon feather-user"
          transform="translate(-5 -3.5)"
        >
          <path
            id="Trazado_411"
            data-name="Trazado 411"
            d="M26.775,30.291v-2.6A5.194,5.194,0,0,0,21.581,22.5H11.194A5.194,5.194,0,0,0,6,27.694v2.6"
            transform="translate(0 -2.419)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Trazado_412"
            data-name="Trazado 412"
            d="M22.388,9.694A5.194,5.194,0,1,1,17.194,4.5,5.194,5.194,0,0,1,22.388,9.694Z"
            transform="translate(-0.806 0)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </figure>
  );
}
