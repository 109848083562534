interface sharedButtonsProps {
  text: string;
  url: string;
}

const shareArticle = async (shareData: sharedButtonsProps): Promise<void> => {
  try {
    await navigator.share(shareData);
  } catch (err) {
    console.log(err);
  }
};

export { shareArticle, type sharedButtonsProps };
