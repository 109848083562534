import continentalLogo from "../../../assets/img/continental-logo.png";

const urlConti = "https://www.bancontinental.com.py/#/";

const FooterLogo = () => {

  return (
    <div className="col-12 col-md-6 col-lg-2 text-lg-center text-start text-white p-0 mt-4 mt-lg-0">
      <div className="d-inline-flex flex-column ">
        <p className="fnt-contisans-footer">Com respaldo do Banco</p>
        <a
          href={urlConti}
          rel="noreferrer"
          target="_blank"
          className="no-decoration"
        >
          <img
            src={continentalLogo}
            loading="lazy"
            alt="Continental Logo"
            className="img-fluid logo-conti"
          />
        </a>
      </div>
    </div>
  );
};

export default FooterLogo;
