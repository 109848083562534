import { Link } from "react-router-dom";
import { INavbarList } from "./INavbarList";
import { handleScroll } from "../layouts/elements/HamburgerMenu";
import { Dropdown } from "bootstrap";

interface INavbarDropDown {
  mainTitle: string;
  nestedPages: INavbarList[] | undefined;
}

const NavbarDropDown = ({ mainTitle, nestedPages }: INavbarDropDown) => {
  const { REACT_APP_STRAPI_IMG } = process.env;
  let dropdownId = mainTitle?.replace(/[^\w\s]/gi, '').replace(/\s+/g, '');

  const toggleDropdown = (idElement: string) => {
    const myDropdownElement = document.getElementById(
      idElement
    ) as HTMLElement;    
    new Dropdown(myDropdownElement, {
      autoClose: true,
      display: 'dynamic',
      reference: 'parent'
    }).toggle()
  }

  return (
    <li className="nav-item">
      <div className="dropdown">
        <span
          className="nav-link  text-blanco fs-myriad fs-myriad-nav dropdown-toggle nav-border-bottom py-4 py-xl-3"
          id={dropdownId}
         // data-bs-toggle="dropdown"
         onClick={() => toggleDropdown(dropdownId)}
        >
          {mainTitle}
          <span className="dropdown-icon"></span>
        </span>
        <ul className="dropdown-menu text-wrap-balance fnt-contisans-small ps-2 ps-xl-0">
          {nestedPages?.map((p) =>
            p.page_slug ? (
              <Link
                key={p.id}
                onClick={handleScroll}
                className="dropdown-item text-wrap-balance fs-myriad fs-myriad-nav ps-0 px-xl-4"
                to={`${p.page_slug}/${p.id}`}
              >
                {p.page_title}
              </Link>
            ) : (
              <a
                href={`${REACT_APP_STRAPI_IMG}${p.page_slug_document}`}
                key={p.id}
                onClick={handleScroll}
                className="dropdown-item text-wrap-balance fs-myriad fs-myriad-nav ps-0 ps-0 px-xl-4"
                target={p.open_new_page ? `_blank` : `_self`}
                rel="noreferrer"
              >
                {p.page_title}
              </a>
            )
          )}
        </ul>
      </div>
    </li>
  );
};

export default NavbarDropDown;
