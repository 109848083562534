import { createContext, Dispatch, useReducer } from 'react';

import IFullFormAbraContaPJData from '../types/AbraContaPJ/IFullFormAbraContaPJData';
import IFullFormAbraContaPFData from '../types/AbraContaPF/IFullFormAbraContaPFData';
import { number } from 'yup';

type TKeyPJ = keyof IFullFormAbraContaPJData;
type TKeyPF = keyof IFullFormAbraContaPFData;

type PartialPJ = Partial<IFullFormAbraContaPJData>;
type PartialPF = Partial<IFullFormAbraContaPFData>;

interface AbraContaPJProviderProps {
  children: JSX.Element | JSX.Element[];
}

type ActionProps = 
  | { payload: number;  type: 'UPDATE_PAGE' }
  | { payload: number;  type: 'UPDATE_TOTAL_PAGES'}
  | { payload: number;  type: 'UPDATE_TOTAL_PAGES'}
  | { payload: boolean; type: 'UPDATE_FORM_ACCESSED' }
  | { payload: PartialPJ; type: 'UPDATE_FORM_DATA_PJ' }
  | { payload: PartialPF; type: 'UPDATE_FORM_DATA_PF' }
  | { type: 'DELETE_FORM_DATA' }

  /* enum ActionProps{
    type = 'UPDATE_PAGE'    
  } */


interface InitStateProps {
  formStep    : { page: number, from: number };
  formAccessed: boolean;
  formData    : PartialPJ | PartialPF | null;
  dispatch    : Dispatch<ActionProps>  | null;
};


const initState: InitStateProps = {
  formStep: { page: 1, from: 5 },
  formData: null,
  formAccessed: false,
  dispatch: null
};

export const AbraContaContext = createContext(initState);

const abraContaReducer = (state: InitStateProps, action: ActionProps): InitStateProps => {
  switch (action.type) {
    case 'UPDATE_PAGE':
      return { ...state, formStep: { ...state.formStep, page: action.payload } };

    case 'UPDATE_TOTAL_PAGES':
      return { ...state, formStep: { ...state.formStep, from: action.payload } };
  
    case 'UPDATE_FORM_ACCESSED':
      return { ...state, formAccessed: action.payload };

    case 'UPDATE_FORM_DATA_PJ':
      return { 
        ...state, 
        formData: ({...state.formData, ...action.payload} as PartialPJ)
      };

    case 'UPDATE_FORM_DATA_PF':
      
      return { 
        ...state, 
        formData: ({...state.formData, ...action.payload} as PartialPF)
      };

    case 'DELETE_FORM_DATA':
      return { ...state, formData: null };

    default:
      return state;
  }
};

export const AbraContaPJProvider = ({ children }: AbraContaPJProviderProps ) => {

  const [state, dispatch] = useReducer(abraContaReducer, initState);

  return (
    <AbraContaContext.Provider value={{...state, dispatch}}>
      { children }
    </AbraContaContext.Provider>
  );
};