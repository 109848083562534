import { Outlet } from "react-router-dom";
import NavBar from "../../../components/navbars/NavBar";
import Footer from "../../../components/footers/Footer";

export function DefaultLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  )
}