const { REACT_APP_URL } = process.env;

const getPageData = async <T>(url: string) => {
  // try {
    /* If the Data has already been fetched, then we just return it from the SessionStorage */
    const sessionData = sessionStorage.getItem(url) || '[]';  
    //Ya no guarda datos en la session ya que si el usuario carga nuevos datos, se tiene que limpiar
    //manualmente el session storage.
    //if( sessionData ) return ( JSON.parse(sessionData) );
    /* If the data does not exist, then we fetch it and save it in the SessionStorage */  
    const resp = await fetch(`${REACT_APP_URL}${url}`);
    const data: T = await resp.json();
    sessionStorage.setItem(url, JSON.stringify(data));
    return data || JSON.parse(sessionData);
  /* } catch (err) {
    //console.log(err);
    throw new Error(err as string);
  } */
};

export default getPageData;
