
const dateInit = new Date('1930-01-01').toISOString().slice(0, 10);
const nowDate = new Date().toISOString().slice(0, 10);

//Metodo para evaluar si la fecha es mayor  al 01/01/1930
const validateDateMore = (parsedDate: Date | undefined) => {        
    if (parsedDate !== undefined) {
        if (parsedDate.toISOString().slice(0, 10) > dateInit) {
            return true;   
        }
        return false;
    }
    return false;
}

//Metodo para validar si la fecha es menor a la actual
const validateDateLess = (parsedDate: Date | undefined) => {          
    if (parsedDate !== undefined) {
        if (nowDate > parsedDate.toISOString().slice(0, 10)) {
            return true;   
        }
        return false;
    }
    return false;
}

export {
    validateDateMore,
    validateDateLess
}