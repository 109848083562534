import { useEffect, useState } from "react";
import CookieConsent from "react-cookie-consent";
import getUserIp from "../../utils/getUserIp";
import { PDF } from "../../views/ForYou/CreditCards/ICreditCardsPage";
import getPageData from "../../utils/getPageData";

interface IUserInfo {
  ip: string;
  geolocation: string;
}

const { REACT_APP_URL, REACT_APP_API_VERSION, REACT_APP_STRAPI_IMG } =
  process.env;

const getGeolocation = async (ip: string) => {
  const { REACT_APP_API_GEOLOCATION } = process.env;
  try {
    const url = `${REACT_APP_API_GEOLOCATION}${ip}/json/`;
    const data = await fetch(url);
    const { latitude, longitude } = await data.json();
    return `${latitude}, ${longitude}`;
  } catch (err) {
    console.log(err);
    return "";
  }
};

const getUserInfo = async () => {
  const userIP: string = await getUserIp();
  const geoData = await getGeolocation(userIP);

  const userInfo: IUserInfo = {
    ip: userIP,
    geolocation: geoData,
  };

  /* We save the cookies */
  document.cookie = `UserInfo=${JSON.stringify(
    userInfo
  )}; path=/; max-age=31536000; secure`;
};

const Cookies = () => {
  const [pdfData, setPDFData] = useState<PDF[]>([]);
  const [errors, setError] = useState(false);

  const saveCookie = async () => {
    const userIP: string = await getUserIp();
    const geoData = await getGeolocation(userIP);    

    const userInfo = {
      ip: userIP,
      coord_geolocation: geoData,
    };

    await fetch(`${REACT_APP_URL!}${REACT_APP_API_VERSION}/api/Cookie`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userInfo),
    });
  };

  useEffect(() => {
    if (document.cookie === "") {
      getUserInfo();
    }
    const creditCardPDF = `${REACT_APP_API_VERSION}/api/CardsDocuments`;
    getPageData<PDF[]>(creditCardPDF)
      .then((pdf) => {
        setPDFData(pdf);
      })
      .catch(() => setError(true));
  }, []);

  return (
    <div>
      <CookieConsent
        location="bottom"
        style={{
          background: "#1D428A",
          left: "50%",
          transform: "translateX(-50%)",
          zIndex: 2000,
          boxShadow: "0 0 3px rgba(0, 0, 0, 0.6)",
        }}
        buttonStyle={{ color: "#000", backgroundColor: "#CEDC00" }}
        containerClasses={
          "d-flex justify-content-center align-items-center container-xl rounded-top p-1"
        }
        buttonClasses="py-2 px-4 rounded rounded-pill fs-6 fnt-contisans"
        buttonText="Aceito"
        expires={365}
        onAccept={() => saveCookie()}
        enableDeclineButton
        declineButtonStyle={{ backgroundColor: "#1D428A" }}
        declineButtonText="Não aceito"
      >
        <div className="p-lg-2 d-flex justify-content-center align-items-center">
          <div className="fnt-contisans-small text-justify text-lg-start">
            Utilizamos cookies para tornar sua experiência única em nosso
            ambiente digital. Ao continuar em nosso site, você aceita e concorda
            com nossos termos e condições. Para mais informações, consulte nossa{" "}
            <a
              href={`${REACT_APP_STRAPI_IMG}${pdfData[0]?.pdfDocument}`}
              target={pdfData[0]?.open_new_page ? "_blank" : "_parent"}
              rel="noreferrer"
              className="link fnt-myriad-bold text-amarillo-nbc"
            >
              {pdfData[0]?.title}
            </a>
          </div>
        </div>
      </CookieConsent>
    </div>
  );
};

export default Cookies;
