import { INoticesAll } from "../INotices";
import { Link } from "react-router-dom";
import ClockIcon from "./icons/ClockIcon";
import CalendarIcon from "./icons/CalendarIcon";

interface Props {
  notices: INoticesAll[];
}

const { REACT_APP_STRAPI_IMG } = process.env;

export default function CardNotices({ notices }: Props): React.ReactElement {
  return (
    <>
      {notices.length > 0 ? (
        notices?.map((item) => (
          <li className="lista-sin-estilo mb-5" key={item?.id}>
            <Link to={`${item?.id}`} className="cursor-pointer link">
              <div className="row">
                <div className="col-lg-3 mx-0 px-0">
                  <figure
                    className="w-100 overflow-hidden rounded"
                    style={{
                      height: "189px",
                    }}
                  >
                    <img
                      src={`${REACT_APP_STRAPI_IMG}${item?.url_image}`}
                      alt={item?.title_news}
                      className="w-100 h-100 object-fit-cover p-0 m-0"
                    />
                  </figure>
                </div>
                <div className="col-lg-9 mt-3 mt-lg-0">
                  <div className="d-flex h-100 flex-column justify-content-between">
                    <div>
                      <p
                        className="text-dark  fs-myriad-title fs-myriad mb-0"
                        dangerouslySetInnerHTML={{ __html: item?.title_news }}
                      />
                      <p
                        className="fnt-contisans-small mt-1 text-gris line-clamp-4"
                        dangerouslySetInnerHTML={{ __html: item?.subtitle_new }}
                      />
                    </div>
                    <div className="row">
                      <div className="col-12 d-flex align-items-center gap-2">
                        <div className="fnt-contisans-small d-flex align-items-center text-gris mb-0 pb-0">
                          <CalendarIcon color="#5b5b62" className="text-gris" />
                          <p className="mb-0 ms-2">{item?.publication_date}</p>
                        </div>
                        <div className="fnt-contisans-small d-flex align-items-center text-gris mb-0 pb-0">
                          <ClockIcon color="#5b5b62" />
                          <p className="mb-0 ms-2">{item?.publication_hour}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </li>
        ))
      ) : (
        <li className="lista-sin-estilo mt-5">
          <p className="text-center fs-myriad-title fs-myriad">
            Nenhuma noticia encontrada
          </p>
        </li>
      )}
    </>
  );
}
