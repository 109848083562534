import { Link } from "react-router-dom";
import { INestedFooter, IFooter } from "../../../types/Footer/IFooter";
import { FooterItems } from "./FooterItems";

interface Props {
  footer_title: string;
  nested_page: INestedFooter[];
}

const FooterForYou = ({ footer_title, nested_footer }: IFooter) => {  
  return (
    <div className="col-12 mt-3 mt-lg-0 col-md-6 col-lg-3 col-xl-2 d-flex flex-column align-items-start mb-lg-4 mb-0 px-0">
      <p className="fnt-contisans-title-footer text-verde-limon mb-2">
        {footer_title}
      </p>
      <FooterItems nested_footer={nested_footer} />
    </div>
  );
};

export default FooterForYou;
