async function transformAndSendData<Type>(
  endpoint: string,
  data: Type
): Promise<Response> {
  const resp = await fetch(endpoint, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (resp.status !== 201) {
    throw new Error("Mail not sent, contact the administrator");
  }

  return resp;
}

async function transformAndSendDataWithDocument(
  endpoint: string,
  data: FormData
): Promise<Response> {  
  const resp = await fetch(endpoint, {
    method: "POST",
    body: data,
  });

  if (!resp.ok) {
    throw new Error("Mail not sent, contact the administrator");
  }

  return resp;
}

export { transformAndSendData, transformAndSendDataWithDocument };
