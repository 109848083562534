import { Link } from "react-router-dom";

interface IFooterTalkToUs {
  sac: string;
  ouvidoria: string;
}

const FooterTalkToUs = ({ sac, ouvidoria }: IFooterTalkToUs) => {
  return (
    <ul className="col-12 lista-sin-estilo lh-lg mt-3 mt-lg-0 col-md-6 col-lg-2 d-flex flex-column align-items-start mb-lg-4 mb-0 px-0">
      <li className="fnt-contisans-title-footer text-verde-limon">
        Atendimento
      </li>

      <li className="text-blanco fnt-contisans-footer">
        SAC{" "}
        <a className="text-verde-limon fw-bold link" href={`tel:${ouvidoria}`}>
          {sac}
        </a>
      </li>

      <li className="text-blanco fnt-contisans-footer">
        Ouvidoria{" "}
        <a className="text-verde-limon fw-bold link" href={`tel:${ouvidoria}`}>
          {ouvidoria}
        </a>
      </li>

      {/* <li className="no-decoration">
        <Link to="/trabalhe-conosco" className="text-blanco link fnt-contisans-footer link">
          Trabalhe Conosco
        </Link>
      </li> */}

      <li className="no-decoration">
        <Link
          to="/denuncias/57"
          className="text-blanco fnt-contisans-footer link"
        >
          Canal de Denúncias
        </Link>
      </li>

      <li className="no-decoration">
        <Link
          to="/titulares/58"
          className="text-blanco fnt-contisans-footer link"
        >
          Canal do Titular de Dados
        </Link>
      </li>
      <li className="no-decoration">
        <Link to="/FAQ/68" className="text-blanco fnt-contisans-footer link">
          Perguntas frequentes
        </Link>
      </li>
      <li className="no-decoration mt-4">
        <Link
          to="/trabalhe-conosco"
          className="text-blanco fnt-contisans-footer link"
        >
          Trabalhe Conosco
        </Link>
      </li>
      <li className="no-decoration">
        <Link
          to="/noticias/69"
          className="text-blanco fnt-contisans-footer link"
        >
          Blog de Notícias
        </Link>
      </li>
    </ul>
  );
};

export default FooterTalkToUs;
