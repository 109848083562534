import React from "react";
export default function ClockIcon({
  color = "#212429",
  className,
}: {
  color: string;
  className?: string;
}): React.ReactElement {
  return (
    <figure className={`cursor-pointer text-sm ${className}`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="20"
        viewBox="0 0 25.406 25.406"
      >
        <g
          id="Icon_feather-clock"
          data-name="Icon feather-clock"
          transform="translate(1 1)"
        >
          <path
            id="Trazado_46"
            data-name="Trazado 46"
            d="M24.739,13.036a11.7,11.7,0,1,1-11.7-11.7,11.7,11.7,0,0,1,11.7,11.7Z"
            transform="translate(-1.333 -1.333)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            id="Trazado_47"
            data-name="Trazado 47"
            d="M8,4v7.022l4.681,2.341"
            transform="translate(3.703 0.681)"
            fill="none"
            stroke="#707070"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </g>
      </svg>
    </figure>
  );
}
