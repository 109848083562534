interface IData {
  ip: string;
}

const {REACT_APP_API_GET_IP} = process.env;

const getUserIp = async (): Promise<string> => {
  try {
    const ipURL = `${REACT_APP_API_GET_IP}?format=json`; //WebPage: https://www.ipify.org/
    const resp = await fetch(ipURL);
    const data: IData = await resp.json();
    return data.ip;
  } catch (error) {
    console.log(error);
    return '';
  }
};

export default getUserIp;
