import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { IIOmbudsmanSection } from "../Ombudsman/IIOmbudsmanSection";
import { getGoogleAnalytics } from "../../hooks/useGoogleAnalytics";
import { IHeaderInfo } from "../ForYou/Anticipation/IAnticipationPage";
import getPageData from "../../utils/getPageData";
import InfoPageHeaderSolidBackground from "../../components/headers/InfoPageHeaderSolidBackground";
import { BreakedHeaderSections } from "../../components/sections/BreakedSections/BreakedHeaderSections";
import { ComplaintsForm } from "./ComplaintsForm";

const { REACT_APP_API_VERSION } = process.env;

const ComplaintsPage = () => {
  const { id_page } = useParams();
  const [fetchedHeaderData, setFetchedHeaderData] = useState<IHeaderInfo[]>([]);
  const [fetchedSectionData, setFetchedSectionData] = useState<
    IIOmbudsmanSection[]
  >([]);

  useEffect(() => {
    getGoogleAnalytics();
    /* getPageID(route_data).then((page_id) => { */
    const riscosHeaderURL = `${REACT_APP_API_VERSION}/api/headerinformations?page_id=${id_page}`;
    getPageData<IHeaderInfo[]>(riscosHeaderURL).then((data) => {
      setFetchedHeaderData(data);
    });
    const leasingSectionURL = `${REACT_APP_API_VERSION}/api/foryou/loans/sections?page_id=${id_page}`;
    getPageData<IIOmbudsmanSection[]>(leasingSectionURL).then((data) => {
      setFetchedSectionData(data);
    });
    /* }); */
  }, [id_page]);

  return (
    <>
      <InfoPageHeaderSolidBackground
        title={fetchedHeaderData[0]?.header_info_title}
      />
      <BreakedHeaderSections
        title={fetchedSectionData[0]?.section_title}
        htmlDescription={fetchedSectionData[0]?.section_description}
      />
      <ComplaintsForm
        //politicaDescription={fetchedSectionData[1]?.section_description}
      />
    </>
  );
};

export default ComplaintsPage;
