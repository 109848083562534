import { INavbarList } from "./INavbarList";
import NavbarDropDown from "./NavbarDropDown";

interface INavbarListProps {
  navbarItems: INavbarList[];
}

const NavBarList = ({ navbarItems }: INavbarListProps) => {
  return (
    <>
      <NavbarDropDown
        mainTitle={navbarItems[0]?.page_title}
        nestedPages={navbarItems[0]?.nested_pages}
      />

      <NavbarDropDown
        mainTitle={navbarItems[1]?.page_title}
        nestedPages={navbarItems[1]?.nested_pages}
      />

      <NavbarDropDown
        mainTitle={navbarItems[2]?.page_title}
        nestedPages={navbarItems[2]?.nested_pages}
      />

      <NavbarDropDown
        mainTitle={navbarItems[3]?.page_title}
        nestedPages={navbarItems[3]?.nested_pages}
      />
      <li className="nav-item">
        <a
          className="text-dark mt-4 mt-xl-1 d-inline-block fs-myriad fs-myriad-nav btn-banking bg-verde-limon"
          aria-current="page"
          href={navbarItems[4]?.page_slug}
          target="_blank"
          rel="noreferrer"
        >
          {navbarItems[4]?.page_title}
        </a>
      </li>
    </>
  );
};

export default NavBarList;
