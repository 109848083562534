import React, { lazy } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

const UnsecurePage = lazy(() => import("./views/UnsecurePage/UnsecurePage"));

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  
  <React.StrictMode>
    {
      window.self === window.top
      ? (<App />)
      : (<UnsecurePage />)
    }
  </React.StrictMode>
  
      
);
