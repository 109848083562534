import { NewsNoticeMonths } from "../INotices";

export default function CardArquivos({
  noticesMonthCategory,
}: {
  noticesMonthCategory: NewsNoticeMonths[];
}) {
  return (
    <>
      <div className="my-4">
        <h2 className="text-dark fs-myriad-title fs-myriad">Arquivos</h2>
        <span className="border-bottom-title"></span>
      </div>
      <ul className="px-0 mx-0">
        {noticesMonthCategory?.map((notice) => (
          <li
            className="lista-sin-estilo border-arquivos pb-3 mb-3 text-gris fs-myriad-md fs-myriad"
            key={notice?.id}
          >
            <a
              href={`/noticias/69?month=${notice.month}&year=${notice?.year}`}
              className="link text-gris"
            >
              {notice.month} {notice.year}
            </a>
          </li>
        ))}
      </ul>
    </>
  );
}
