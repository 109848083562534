import IHeaderInformation from "../../types/HeaderInformationTypes/IHeaderInformationTypes";
import SectionHeader from "../layouts/elements/SectionHeader";

interface IHeader extends IHeaderInformation {
  backgroundColor?: string;
}

const InfoPageHeaderSolidBackground = ({
  title,
  backgroundColor = "#15387C",
  smallTitle,
  description,
}: IHeader) => {
  return (
    <header
      id="navbarNav"
      className="header"
      style={{
        backgroundColor: backgroundColor,
      }}
    >
      <div className="d-flex d-xl-block align-items-center justify-content-center">
        <SectionHeader
          vClassNameHeader="header-section-background"
          title={title}
          smallTitle={smallTitle}
          description={description}
        />
      </div>
    </header>
  );
};

export default InfoPageHeaderSolidBackground;
