import { useFooterData } from "../../hooks/useFooterData";
import { useEffect, useState } from "react";
import FooterAboutUs from "./FirstRow/FooterAboutUs";
import FooterForYou from "./FirstRow/FooterForYou";
import FooterForYourCompany from "./FirstRow/FooterForYourCompany";
import FooterTalkToUs from "./FirstRow/FooterTalkToUs";
import FooterInternetApps from "./FirstRow/FooterInternetApps";
import FooterEnderecoMatrix from "./SecondRow/FooterEnderecoMatrix";
import FooterSocialMedia from "./SecondRow/FooterSocialMedia";
import FooterLogo from "./SecondRow/FooterLogo";
import getPageData from "../../utils/getPageData";
import { IFooter } from "../../types/Footer/IFooter";

const {REACT_APP_API_VERSION} = process.env;

const URL_footer = `${REACT_APP_API_VERSION}/api/footer`;

function FooterList() {
  let { fetchedData, storeAppData } = useFooterData();
  const [footerDocuments, setFooterDocuments] = useState<IFooter[]>([]);

  useEffect(() => {
    getPageData<IFooter[]>(URL_footer).then((res) =>      
      setFooterDocuments(res)
    );
  }, []);

  return (
    <>
      <div className="row justify-content-center justify-content-lg-between">
        <FooterAboutUs
          footer_title={footerDocuments[0]?.footer_title}
          nested_footer={footerDocuments[0]?.nested_footer}
        />
        <FooterForYou
          footer_title={footerDocuments[1]?.footer_title}
          nested_footer={footerDocuments[1]?.nested_footer}
        />
        <FooterForYourCompany
          footer_title={footerDocuments[2]?.footer_title}
          nested_footer={footerDocuments[2]?.nested_footer}
        />
        <FooterTalkToUs
          sac={fetchedData.busines_info_telephone}
          ouvidoria={fetchedData.busines_info_ombudsman_phone}
        />
        <FooterInternetApps data={storeAppData} />
        <FooterEnderecoMatrix
          matrixTitle={fetchedData.busines_info_matrix_title}
          matrixInfo={fetchedData.busines_info_matrix_address}
        />
        <FooterSocialMedia
          facebook={fetchedData.busines_info_facebook}
          instagram={fetchedData.busines_info_instagram}
          linkedin={fetchedData.busines_info_linkedin}
        />
        <FooterLogo />
      </div>
    </>
  );
}

export default FooterList;
