import "../../assets/css/modules/style-loader.css";
const LoaderPage = () => {
  return (
    <main className="vh-100 w-100 ">
      <section className="w-100 h-100 fixed-top d-flex align-items-center justify-content-center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      </section>
    </main>
  );
};

export { LoaderPage };
