import IHeaderInformation from "../../types/HeaderInformationTypes/IHeaderInformationTypes";
import SectionHeader from "../../components/layouts/elements/SectionHeader";

interface IHeaderProps extends IHeaderInformation {
  topTitle?: boolean;
  vclassName?: string
}

const InfoPageHeader = ({
  image,
  description,
  title,
  topTitle = false,
  smallTitle,
  vclassName = ""
}: IHeaderProps) => {
  return (
    <header
      id="navbarNav"
      className={`header header-imagen bg-oscuro-opacidad`}
      style={{
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
      }}
    >
      <div className={`d-flex d-xl-block align-items-center justify-content-center ${vclassName}`}>
        <SectionHeader
          topTitle={topTitle}
          border={false}
          title={title}
          smallTitle={smallTitle}
          description={description}
          vClassNameHeader={"header-section"}
        />
      </div>
    </header>
  );
};

export default InfoPageHeader;
