interface IFooterEnderecoMatrix {
  matrixInfo: string;
  matrixTitle: string;
}

const FooterEnderecoMatrix = ({
  matrixInfo,
  matrixTitle,
}: IFooterEnderecoMatrix) => {
  return (
    /*  my-2 my-lg-0 col-12 col-md-6 col-lg-5 d-flex flex-column align-items-start*/
    <div className="col-12 col-md-6 col-lg-3  d-lg-block text-white mt-4 mt-lg-0 px-0">
      <p className="fnt-contisans-title-footer" dangerouslySetInnerHTML={{__html: matrixTitle}}/>
      <p className="respetar-saltos text-start fnt-contisans-footer" dangerouslySetInnerHTML={{__html: matrixInfo}}/>
    </div>
  );
};

export default FooterEnderecoMatrix;
