import * as yup from "yup";
import validaNameCompleto from "../FormCard/FormStepOne/validaNameCompleto";
import { parse } from "date-fns";
import { validateDateMore } from "../FormCard/FormStepTwo/validateDates";

//const date = new Date().toISOString().slice(0, 10);
let parsedDate: Date;
const parseDateString = (value: unknown, originalValue: unknown) => {
  if (typeof originalValue === "string") {
    //let dateString = originalValue as string;
    let newDateString: string = "";
    if (originalValue.includes("/")) {
      const dateParts = originalValue.split("/");
      newDateString = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
      parsedDate = parse(newDateString as string, "yyyy-MM-dd", new Date());
    } else {
      parsedDate = value as Date;
    }
    /* const parsedDate = isDate(originalValue)
      ? value  this make sure that a value is provided
      : parse(newDateString as string, "yyyy-MM-dd", new Date()); */

    return parsedDate;
  }

  return;
};

const ComplaintsSchema = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const myRegEx =
    /^([ a-zA-ZáéíóúÁÉÍÓÚãẽĩõũỹÃẼĨÕŨỸ_ ])+( [a-zA-ZáéíóúÁÉÍÓÚãẽĩõũỹÃẼĨÕŨỸ_]+)*$/;

  const schema = yup.object().shape({
    identifications: yup.string().required("* Seleccione una opcion"),
    outro: yup.string(),
    administrador: yup.string(),
    /* data: yup
      .string()
      .min(5, "* Digite a data completa")
      .max(75, "* Digite a data completa")
      .matches(myRegEx, "* O nome deve conter caracteres válidos"), */

    data: yup
      .date()
      .transform(parseDateString)
      .required("* Digite a data completa")
      .typeError("* Digite a data completa")
      .test("validateDateMore", "* Digite a data válida", (date) =>
        validateDateMore(date)
      ) /* 
      .test(
        "validateDateLess",
        "* Digite a data válida",
        (date) => validateDateLess(date)
      ), */,
    /* .test(
        "validaNameCompleto",
        "* Digite pelo menos um nome e sobrenome",
        (name) => validaNameCompleto(name)
      )
      .required("* Digite o local completo"), */
    local: yup
      .string()
      .min(5, "* Digite o local completo")
      .max(75, "* Digite o local completo")
      .matches(myRegEx, "* O local deve conter caracteres válidos"),
    /*  .test(
        "validaNameCompleto",
        "* Digite pelo menos um local e sobrenome",
        (name) => validaNameCompleto(name)
      )
      .required("* Digite o local completo"), */

    denunciado: yup
      .string()
      .min(5, "* Digite o nome valido")
      .max(75, "* Digite o nome valido")
      .matches(myRegEx, "* O nome deve conter caracteres válidos")
      .test(
        "validaNameCompleto",
        "* Digite pelo menos um nome e sobrenome",
        (name) => validaNameCompleto(name)
      )
      .required("* Campo obrigatório."),

    cargo: yup
      .string()
      .min(5, "* Digite um cargo válido")
      //.max(12, "Digite un cargo valido")
      .required("* Campo obrigatório."),

    denuncia: yup
      .string()
      .min(10, "* Descreva sua denúncia com o maior detalhamento possível")
      //.max(50, "* Digite o estado válido")
      //.matches(myRegEx, "* O nome deve conter caracteres válidos")
      .required("* Campo obrigatório."),

    nome: yup.string(),
    /*       .min(5, "* Digite o nome completo")
      .max(75, "* Digite o nome completo") */
    //.matches(myRegEx, "* O nome deve conter caracteres válidos")
    /* .test(
        "validaNameCompleto",
        "* Digite pelo menos um nome e sobrenome",
        (name) => validaNameCompleto(name)
      ), */
    //.required("* Campo obrigatório."),

    emailDenuncia: yup
      .string()
      .email("* Digite um email válido")
      /* .min(5, "* Digite um email")
      .max(75, "* Digite um email válido") */
      .transform((val: string, origVal) => val.trim()),

    /* acceptTerms: yup
      .boolean()
      .oneOf([true], "* Aceite os termos e condições")
      .required("* Campo obrigatório"), */
  });

  return schema;
};

export default ComplaintsSchema;
