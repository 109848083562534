import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import { getGoogleAnalytics } from "../../../hooks/useGoogleAnalytics";
import getPageData from "../../../utils/getPageData";
import { INotices, INoticesAll } from "../INotices";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { useNavigate, useSearchParams } from "react-router-dom";
const { REACT_APP_API_VERSION } = process.env;
let noticesURL = "";

const useNoticesPage = () => {
  const [searchParams] = useSearchParams();
  const [fetchedNotices, setFetchedNotices] = useState<INoticesAll[]>([]);
  const [inputSearch, setInputSearch] = useState(
    searchParams.get("keySearch") ?? ""
  );
  const [endpoint, setEndpoint] = useState("");
  const navigate = useNavigate();
  const [page, setPage] = useState({
    page: 1,
    perPage: 5,
  });

  const getData = ({ endpoint }: { endpoint: string }) => {
    setEndpoint((prev) => {
      if (prev !== endpoint) {
        setPage({
          page: 1,
          perPage: 1,
        });
        setFetchedNotices([]);
        return endpoint;
      }
      return prev;
    });
    return getPageData<INotices[]>(endpoint).then((data) => {
      return data?.map((item: INoticesAll) => {
        const newTodayDate = format(
          new Date(item?.publication_date),
          "dd LLLL yyyy",
          {
            locale: ptBR,
          }
        );
        const newHourPublished = format(
          new Date(item?.publication_date),
          "HH:mm",
          {
            locale: ptBR,
          }
        );
        return {
          id: item?.id,
          title_news: item?.title_news,
          description_new: item?.description_new,
          subtitle_new: item?.subtitle_new,
          publication_date: newTodayDate,
          publication_hour: newHourPublished,
          totalCount: item?.totalCount,
          author_name: item?.author_name,
          url_header_image: item?.url_header_image,
          url_image: item?.url_image,
        };
      });
    });
  };

  useEffect(() => {
    getGoogleAnalytics();
    if (inputSearch !== "" || searchParams.get("keySearch") !== null) {
      noticesURL = `${REACT_APP_API_VERSION}/api/newByKeyboard?keyword=${inputSearch}&page=${page?.page}&pageSize=${page.perPage}`;
      setEndpoint(noticesURL);
    } else {
      noticesURL = `${REACT_APP_API_VERSION}/api/news?page=${page?.page}&pageSize=${page.perPage}`;
      setEndpoint(noticesURL);
    }

    if (searchParams.get("month") !== null && searchParams.get("year")) {
      noticesURL = `${REACT_APP_API_VERSION}/api/newsByMonthYear?year=${searchParams.get(
        "year"
      )}&month=${searchParams.get("month")}&page=${page?.page}&pageSize=${
        page.perPage
      }`;
      setEndpoint(noticesURL);
    }
    getData({ endpoint: noticesURL }).then((res) => 
      setFetchedNotices([...fetchedNotices, ...res])
    );
    
  }, [page.page]);

  const handleMoreNews = () => {
    setPage((prev) => ({
      page: prev.page + 1,
      perPage: 5,
    }));
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) =>
    setInputSearch(e.target.value);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (inputSearch !== "") {
      navigate(`${window.location.pathname}?keySearch=${inputSearch}`);
      noticesURL = `${REACT_APP_API_VERSION}/api/newByKeyboard?keyword=${inputSearch}&page=${page?.page}&pageSize=${page.perPage}`;
    } else {
      navigate(`${window?.location?.pathname}`);
      noticesURL = `${REACT_APP_API_VERSION}/api/news?page=${page?.page}&pageSize=${page.perPage}`;
    }
    getData({ endpoint: noticesURL }).then((res) => {
      setFetchedNotices([...res]);
    });
  };

  return {
    fetchedNotices,
    handleMoreNews,
    handleSubmit,
    handleChange,
    inputSearch,
    currentPage: page.page,
  };
};

export { useNoticesPage };
