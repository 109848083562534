import { useRef, useState } from "react";


const useCaptcha = () => {
  const CAPTCHA_KEY = "6LfhEwMhAAAAAPErF-BzXOdnneFqv_4keemFpBrq";
  const captchaRef = useRef<any>(null);
  const mailModal = useRef<any>(null);
  const [captchaError, setCaptchaError] = useState("");
  const [sendingEmail, setSendingEmail] = useState(false);

  const onChangeCaptcha = () => {    
    const token = captchaRef.current.getValue();
    if (token) {
      setCaptchaError("");
      return;
    }
    setCaptchaError("* Complete Captcha");
  };  

  return {
    CAPTCHA_KEY,
    captchaRef,
    onChangeCaptcha,
    mailModal,
    captchaError,
    setCaptchaError,
    sendingEmail,
    setSendingEmail
  };
};

export {
    useCaptcha
}
