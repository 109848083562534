import { shareArticle, sharedButtonsProps } from "../../hooks/shareArticles";
import ShareImg from "../icons/img/share-alt-regular.png";

interface Props {
  sharedData: sharedButtonsProps
}


const ShareIcon = ({
  sharedData
}: Props) => {
  return (
    <figure
      className="cursor-pointer"
      onClick={async () => {
        await shareArticle(sharedData);
      }}
    >
      <img src={ShareImg} alt="Boton para compartir" />
    </figure>
  );
};

export default ShareIcon;
